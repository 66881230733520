export enum MutationFeedback {
	OK = 'OK',
	OK_CHECK_IN = 'OK_CHECK_IN',
	OK_CHECK_OUT = 'OK_CHECK_OUT',
	INPUT_ERROR = 'INPUT_ERROR',
	LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
	VISITOR_NOT_FOUND = 'VISITOR_NOT_FOUND',
	NO_CAPACITY = 'NO_CAPACITY',
	VISITOR_IS_BANNED = 'VISITOR_IS_BANNED',
	VISITOR_ALREADY_CHECKED_IN = 'VISITOR_ALREADY_CHECKED_IN',
	VISITOR_NOT_CHECKED_IN = 'VISITOR_NOT_CHECKED_IN',
}
