import { html, TemplateResult } from 'lit';
import { EmptyLocation, LocationData } from '../location/data';
import { checkIn, checkOut } from '../check-in/check-in-or-out';
import { Failure } from '@mrhenry/failure';
import { UpdateFailureAction } from '../failure/slice';
import { Dispatch } from 'redux';
import { UpdateCheckInOrOutFeedbackAction } from '../check-in/slice';

export function renderCheckInOrOutCheckOutForm( dispatch: Dispatch<UpdateFailureAction|UpdateCheckInOrOutFeedbackAction>, location: LocationData ): TemplateResult {
	if ( location === EmptyLocation ) {
		throw new Error( '[invariant] : location must exist' );
	}

	let debounce = false;

	const stopSubmissions = () => {
		debounce = true;
	};

	const enableSubmissions = () => {
		debounce = false;
	};

	const submissionsAreStopped = () => {
		return debounce;
	};

	async function handleCheckInClick( event: MouseEvent ) {
		event.stopPropagation();
		event.preventDefault();

		if ( !event.target ) {
			return;
		}

		if ( !( 'form' in event.target ) ) {
			return;
		}

		const el = event.target as HTMLInputElement;
		if ( !el.form ) {
			return;
		}

		const form = el.form;
		if ( !formIsCheckInOrOutForm( form ) ) {
			return;
		}

		const visitorToken = form.visitorToken.value;
		if ( !visitorToken ) {
			return;
		}

		form.visitorToken.value = '';

		if ( submissionsAreStopped() ) {
			return;
		}

		await stopSubmissions();
		const checkInOrOutFeedback = await checkIn( location.token, visitorToken );
		await enableSubmissions();

		if ( Failure.isFailure( checkInOrOutFeedback ) ) {
			dispatch( {
				type: 'UPDATE_FAILURE',
				failure: checkInOrOutFeedback,
			} );

			return;
		}

		dispatch( {
			type: 'UPDATE_CHECK_IN_OR_OUT_FEEDBACK',
			checkInOrOutFeedback: checkInOrOutFeedback,
		} );

		return;
	}

	async function handleCheckOutClick( event: MouseEvent ) {
		event.stopPropagation();
		event.preventDefault();

		if ( !event.target ) {
			return;
		}

		if ( !( 'form' in event.target ) ) {
			return;
		}

		const el = event.target as HTMLInputElement;
		if ( !el.form ) {
			return;
		}

		const form = el.form;
		if ( !formIsCheckInOrOutForm( form ) ) {
			return;
		}

		const visitorToken = form.visitorToken.value;
		if ( !visitorToken ) {
			return;
		}

		form.visitorToken.value = '';

		if ( submissionsAreStopped() ) {
			return;
		}

		await stopSubmissions();
		const checkInOrOutFeedback = await checkOut( location.token, visitorToken );
		await enableSubmissions();

		if ( Failure.isFailure( checkInOrOutFeedback ) ) {
			dispatch( {
				type: 'UPDATE_FAILURE',
				failure: checkInOrOutFeedback,
			} );

			return;
		}

		dispatch( {
			type: 'UPDATE_CHECK_IN_OR_OUT_FEEDBACK',
			checkInOrOutFeedback: checkInOrOutFeedback,
		} );

		return;
	}

	return checkInOrOutTemplate( location, handleCheckInClick, handleCheckOutClick );
}

function checkInOrOutTemplate( location: LocationData, handleCheckInClick: ( e: MouseEvent ) => void, handleCheckOutClick: ( e: MouseEvent ) => void ): TemplateResult {
	const submitListener = {
		handleEvent: ( event: Event ) => {
			event.preventDefault();
			event.stopPropagation();
		},
	};

	return html`
		<div class="background" style="background-image: url(${location.additionalInformation.checkInBackgroundImage}?w=1440)"></div>
		<div class="wrapper">
			<h1>${location.name}</h1>

			<form
				id="checkin-form"
				action=""
				class="form inline-form"
				@submit=${submitListener}
			>
				<div class="form-field form-field--half">
					<input
						type="text"
						class="form-input"
						name="visitorToken"
						placeholder="${window.translations.setupLocation.visitorTokenPlaceholderText}"
						autocomplete="off"
						required
					>
				</div>

				<div class="form-field form-field--quarter">
					<input
						type="submit"
						class="button"
						name="check_in"
						value="${window.translations.setupLocation.checkInButtonText}"
						@click=${handleCheckInClick}
					>
				</div>

				<div class="form-field form-field--quarter">
					<input
						type="submit"
						class="button button--red"
						name="check_out"
						value="${window.translations.setupLocation.checkOutButtonText}"
						@click=${handleCheckOutClick}
					>
				</div>
			</form>
		</div>
		<div class="wrapper is-small">
			<p>${window.translations.setupLocation.instructionsText}</p>
		</div>
	`;
}

interface CheckInForm extends HTMLFormElement {
	visitorToken: HTMLInputElement;
}

function formIsCheckInOrOutForm( form: HTMLFormElement ): form is CheckInForm {
	return 'visitorToken' in form && 'value' in form.visitorToken;
}
