export async function calculateVersion() {
	try {
		const src = document.currentScript?.getAttribute( 'src' );
		if ( !src ) {
			return;
		}

		const resp = await fetch( src );
		if ( !resp.ok ) {
			return;
		}

		const contents = await ( await resp.blob() ).arrayBuffer();
		const hashAsArrayBuffer = await crypto.subtle.digest( 'SHA-256', contents );
		const uint8ViewOfHash = new Uint8Array( hashAsArrayBuffer );
		const hashAsString = Array.from( uint8ViewOfHash ).map( ( b ) => {
			return b.toString( 16 ).padStart( 2, '0' );
		} ).join( '' );

		return hashAsString.slice( -5 );
	} catch {
		return '';
	}
}
