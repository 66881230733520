import { FailureWithDetails } from '../failure/data';
import { MutationFeedback } from './data';

export type UpdateCheckInOrOutFeedbackAction = {
	readonly type: 'UPDATE_CHECK_IN_OR_OUT_FEEDBACK';
	readonly checkInOrOutFeedback?: MutationFeedback;
}

interface State {
	readonly checkInOrOutFeedback?: MutationFeedback;
	readonly failure?: FailureWithDetails;
}

export function sliceCheckInOrOutFeedback<T extends State>( state: T, action: UpdateCheckInOrOutFeedbackAction ): T {
	if ( !action.checkInOrOutFeedback ) {
		return {
			...state,
			checkInOrOutFeedback: undefined,
		};
	}

	return {
		...state,
		checkInOrOutFeedback: action.checkInOrOutFeedback,
		failure: undefined, /* clear the error when a check in went successfully */
	};
}
