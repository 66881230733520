import { Failure } from '@mrhenry/failure';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';
import { FailureWithDetails, UnknownSetupError } from '../failure/data';
import { MutationFeedback } from './data';

export async function checkIn( locationToken: string, visitorToken: string ): Promise<MutationFeedback|FailureWithDetails> {
	bugsnagClient.addMetadata( 'check-in', {
		locationToken: locationToken,
		visitorToken: visitorToken,
	} );

	try {
		const response = await fetch( 'https://study360-api.mrhenry.eu/graphql', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( {
				query: `
					mutation (
						$locationToken: String!,
						$visitorToken: String!
					) {
						checkIn(locationToken: $locationToken, visitorToken: $visitorToken)
					}
				`,
				variables: {
					locationToken: locationToken,
					visitorToken: visitorToken,
				},
			} ),
		} );

		if ( !response.ok ) {
			bugsnagClient.notify( new Error( `[study360 check in] API Error: ${response.status}: ${response.statusText}` ) );

			return Failure.from( `API Error: ${response.status}: ${response.statusText}`, UnknownSetupError );
		}

		const respBody = await response.json();
		if ( responseIsMutationFeedback( respBody?.data?.checkIn ) ) {
			if ( respBody.data.checkIn === MutationFeedback.OK ) {
				return MutationFeedback.OK_CHECK_IN;
			}

			return respBody.data.checkIn;
		}

		bugsnagClient.notify( new Error( '[study360 check in] received unexpected data from API' ) );

		return Failure.from( 'received unexpected data from API', UnknownSetupError );
	} catch ( err ) {
		bugsnagClient.notify( err );

		return Failure.from( err.message, UnknownSetupError );
	}
}

export async function checkOut( locationToken: string, visitorToken: string ): Promise<MutationFeedback|FailureWithDetails> {
	bugsnagClient.addMetadata( 'check-in', {
		locationToken: locationToken,
		visitorToken: visitorToken,
	} );

	try {
		const response = await fetch( 'https://study360-api.mrhenry.eu/graphql', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( {
				query: `
					mutation (
						$locationToken: String!,
						$visitorToken: String!
					) {
						checkOut(locationToken: $locationToken, visitorToken: $visitorToken)
					}
				`,
				variables: {
					locationToken: locationToken,
					visitorToken: visitorToken,
				},
			} ),
		} );

		if ( !response.ok ) {
			bugsnagClient.notify( new Error( `[study360 check in] API Error: ${response.status}: ${response.statusText}` ) );

			return Failure.from( `API Error: ${response.status}: ${response.statusText}`, UnknownSetupError );
		}

		const respBody = await response.json();
		if ( responseIsMutationFeedback( respBody?.data?.checkOut ) ) {
			if ( respBody.data.checkOut === MutationFeedback.OK ) {
				return MutationFeedback.OK_CHECK_OUT;
			}

			return respBody.data.checkOut;
		}

		bugsnagClient.notify( new Error( '[study360 check in] received unexpected data from API' ) );

		return Failure.from( 'received unexpected data from API', UnknownSetupError );
	} catch ( err ) {
		bugsnagClient.notify( err );

		return Failure.from( err.message, UnknownSetupError );
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function responseIsMutationFeedback( message: any ): message is MutationFeedback {
	if (
		!message ||
		( 'string' !== typeof message )
	) {
		return false;
	}

	switch ( message ) {
		case MutationFeedback.OK:
			return true;
		case MutationFeedback.INPUT_ERROR:
			return true;
		case MutationFeedback.LOCATION_NOT_FOUND:
			return true;
		case MutationFeedback.VISITOR_NOT_FOUND:
			return true;
		case MutationFeedback.NO_CAPACITY:
			return true;
		case MutationFeedback.VISITOR_IS_BANNED:
			return true;
		case MutationFeedback.VISITOR_ALREADY_CHECKED_IN:
			return true;
		case MutationFeedback.VISITOR_NOT_CHECKED_IN:
			return true;

		default:
			return false;
	}
}
