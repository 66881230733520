import { html, TemplateResult } from 'lit';
import { EmptyLocation, LocationData } from '../location/data';
import '../globals/globals';

export function renderSetupLocationForm( location: LocationData ): TemplateResult {
	if ( location !== EmptyLocation ) {
		throw new Error( '[invariant] : EmptyLocation expected' );
	}

	return html`
		<div class="wrapper">
			<form action="/" method="GET" class="form inline-form">
				<div class="form-field form-field--3-quarter">
					<input
						autocomplete="off"
						class="form-input"
						name="location-token"
						placeholder="${window.translations.setupLocation.locationTokenPlaceholder}"
						required
						type="text"
					/>
				</div>

				<div class="form-field form-field--quarter">
					<button class="button button--inverted">
						${window.translations.setupLocation.submitButtonText}
					</button>
				</div>
			</form>
		</div>
	`;
}
