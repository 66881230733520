export type LocationData = {
	readonly additionalInformation: {
		checkInBackgroundImage?: string
	};
	readonly id: string;
	readonly name: string;
	readonly token: string;
}

export const EmptyLocation: LocationData = ( () => {
	const x = {
		additionalInformation: {},
		id: '',
		name: '',
		token: '',
	};

	Object.freeze( x );

	return x;
} )();
