import { EmptyLocation, LocationData } from './data';
import { Store, Unsubscribe, Action } from 'redux';

interface State {
	readonly location: LocationData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function locationDataSubscriber<T extends State>( store: Store<T, Action<any>>, cb: ( location: LocationData ) => void ): Unsubscribe {
	let previousState : LocationData|null = null;

	return store.subscribe( () => {
		const newState = store.getState().location;
		if ( previousState === EmptyLocation && newState === EmptyLocation ) {
			return;
		}

		if (
			previousState?.id === newState.id &&
			previousState?.name === newState.name &&
			previousState?.token === newState.token &&
			JSON.stringify( previousState?.additionalInformation ) === JSON.stringify( newState.additionalInformation )
		) {
			return;
		}

		cb( newState );
		previousState = newState;
	} );
}
