import { EmptyLocation, LocationData } from './location/data';
import { noticeSubscriber } from './notice-bar/subscribe';
import { hydrateLocation } from './location/hydrate';
import { locationDataSubscriber } from './location/subscribe';
import { render } from 'lit';
import { renderCheckInOrOutCheckOutForm } from './check-in-or-out-form/view';
import { renderSetupLocationForm } from './setup-location-form/view';
import { store } from './store/store';
import { FailureWithDetails } from './failure/data';
import { renderNotice } from './notice-bar/view';
import { MutationFeedback } from './check-in/data';
import { calculateVersion } from './version/version';

// Location
locationDataSubscriber( store, ( location: LocationData ) => {
	const root = document.getElementById( 'content' );
	if ( !root ) {
		throw new Error( '[invariant] : content element must exist' );
	}

	// Remove all children.
	while ( root.firstChild ) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		root.removeChild( root.lastChild! );
	}

	if ( location === EmptyLocation ) {
		render(
			renderSetupLocationForm( location ),
			root
		);

		return;
	}

	render(
		renderCheckInOrOutCheckOutForm( store.dispatch, location ),
		root
	);

	return;
} );

hydrateLocation( store.dispatch );

// Failures
noticeSubscriber( store, ( failure?: FailureWithDetails, checkInOrOutFeedback?: MutationFeedback ) => {
	const root = document.getElementById( 'notice' );
	if ( !root ) {
		throw new Error( '[invariant] : notice element must exist' );
	}

	setTimeout( () => {
		store.dispatch( {
			type: 'UPDATE_CHECK_IN_OR_OUT_FEEDBACK',
			checkInOrOutFeedback: undefined,
		} );

		store.dispatch( {
			type: 'UPDATE_FAILURE',
			failure: undefined,
		} );
	}, 5000 );

	render(
		renderNotice( failure, checkInOrOutFeedback ),
		root
	);
} );

calculateVersion().then( ( version ) => {
	if ( !version ) {
		return;
	}

	const versionContainer = document.createElement( 'DIV' );
	versionContainer.innerText = version.toLocaleUpperCase();

	versionContainer.style.bottom = '5px';
	versionContainer.style.color = 'rgb(255, 255, 255, 0.8)';
	versionContainer.style.fontSize = '11px';
	versionContainer.style.position = 'absolute';
	versionContainer.style.right = '5px';

	requestAnimationFrame( () => {
		document.body.appendChild( versionContainer );
	} );
} );
