export enum Language {
	EN = 'EN',
	NL = 'NL',
}

export function currentLanguage(): Language {
	switch ( document.documentElement?.getAttribute( 'lang' ) ) {
		case 'en':
			return Language.EN;

		case 'nl':
			return Language.NL;

		default:
			return Language.EN;
	}
}
