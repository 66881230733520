import { FailureWithDetails } from './data';

export type UpdateFailureAction = {
	readonly type: 'UPDATE_FAILURE';
	readonly failure?: FailureWithDetails;
}

interface State {
	readonly failure?: FailureWithDetails;
}

export function sliceFailure<T extends State>( state: T, action: UpdateFailureAction ): T {
	if ( !action.failure ) {
		return {
			...state,
			failure: undefined,
		};
	}

	return {
		...state,
		failure: action.failure,
	};
}
