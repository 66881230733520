import { html, TemplateResult } from 'lit';
import { FailureWithDetails } from '../failure/data';
import { MutationFeedback } from '../check-in/data';

export function renderNotice( failure?: FailureWithDetails, checkInOrOutFeedback?: MutationFeedback ): TemplateResult {
	const out: Array<TemplateResult> = [];

	if ( failure ) {
		out.push(
			renderNoticeMultipleSentences( failure.details.displayMessage, true )
		);
	}

	if ( checkInOrOutFeedback ) {
		if ( checkInOrOutFeedback === MutationFeedback.OK_CHECK_IN ) {
			out.push(
				renderNoticeOneSentence( window.translations.successMessages.okCheckIn, false )
			);


		} else if ( checkInOrOutFeedback === MutationFeedback.OK_CHECK_OUT ) {
			out.push(
				renderNoticeOneSentence( window.translations.successMessages.okCheckOut, false )
			);

		} else {
			out.push(
				renderNoticeOneSentence(
					displayMutationFeedbackMessage( checkInOrOutFeedback ),
					true
				)
			);
		}
	}

	return html`${out}`;
}

function renderNoticeOneSentence( text: string, isError: boolean ) {
	let modalType = 'success';

	if ( isError ) {
		modalType = 'error';
	}

	return html`
		<div class="modal modal--${modalType}">
			${text}
			<button class="modal__close" @click=${function( this: HTMLButtonElement ) {
		try {
			this.parentElement?.remove();
		} catch ( _ ) { }
	}}>
				&times;
			</button>
		</div>
	`;
}

function renderNoticeMultipleSentences( text: string[], isError: boolean ) {
	let modalType = 'success';

	if ( isError ) {
		modalType = 'error';
	}

	return html`
		<div class="modal modal--${modalType}">
	${text.map( ( message, index ) => {
		if ( index === ( text.length - 1 ) ) {
			return message;
		}

		return html`${message}<br>`;
	} )}

	<button class="modal__close" @click=${function( this: HTMLButtonElement ) {
		try {
			this.parentElement?.remove();
		} catch ( _ ) { }
	}}>
				&times;
			</button>
		</div>`;
}

function displayMutationFeedbackMessage( message: MutationFeedback ): string {
	switch ( message ) {
		case 'INPUT_ERROR':
			throw new Error( '[invariant] INPUT_ERROR is unexpected in check in / check out' );
		case 'LOCATION_NOT_FOUND':
			return window.translations.checkInOrOutFeedbackMessages.locationNotFound;
		case 'VISITOR_NOT_FOUND':
			return window.translations.checkInOrOutFeedbackMessages.visitorNotFound;
		case 'NO_CAPACITY':
			return window.translations.checkInOrOutFeedbackMessages.noCapacity;
		case 'VISITOR_IS_BANNED':
			return window.translations.checkInOrOutFeedbackMessages.visitorIsBanned;
		case 'VISITOR_ALREADY_CHECKED_IN':
			return window.translations.checkInOrOutFeedbackMessages.visitorAlreadyCheckedIn;
		case 'VISITOR_NOT_CHECKED_IN':
			return window.translations.checkInOrOutFeedbackMessages.visitorNotCheckedIn;
	}

	throw new Error( `[invariant] no message provided for MutationFeedback : ${message}` );
}
