import { Failure } from '@mrhenry/failure';

export type ErrorDetails = {
	displayMessage: Array<string>;
}

export const UnknownCheckInError = {
	displayMessage: window.translations.errorMessages.unknownAtCheckIn,
};

export const UnknownSetupError = {
	displayMessage: window.translations.errorMessages.unknownAtSetup,
};

export type FailureWithDetails = Failure<ErrorDetails>;
