import { Store, Unsubscribe, Action } from 'redux';
import { MutationFeedback } from '../check-in/data';
import { FailureWithDetails } from '../failure/data';

interface State {
	readonly failure?: FailureWithDetails;
	readonly checkInOrOutFeedback?: MutationFeedback;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function noticeSubscriber<T extends State>( store: Store<T, Action<any>>, cb: ( failure?: FailureWithDetails, checkInOrOutFeedback?: MutationFeedback ) => void ): Unsubscribe {
	let previousFailureState: FailureWithDetails | undefined;
	let previousFeedbackState: MutationFeedback | undefined;

	return store.subscribe( () => {
		const newFailureState = store.getState().failure;
		const newFeedbackState = store.getState().checkInOrOutFeedback;
		if ( newFailureState === previousFailureState && newFeedbackState === previousFeedbackState ) {
			return;
		}

		cb( newFailureState, newFeedbackState );
		previousFailureState = newFailureState;
		previousFeedbackState = newFeedbackState;
	} );
}
