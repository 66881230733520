import { createStore } from 'redux';
import { EmptyLocation, LocationData } from '../location/data';
import { sliceLocation, UpdateLocationAction } from '../location/slice';
import { sliceFailure, UpdateFailureAction } from '../failure/slice';
import { FailureWithDetails } from '../failure/data';
import { sliceCheckInOrOutFeedback, UpdateCheckInOrOutFeedbackAction } from '../check-in/slice';
import { MutationFeedback } from '../check-in/data';

interface CheckInPageState {
	readonly location: LocationData;
	readonly failure?: FailureWithDetails;
	readonly checkInFeedback?: MutationFeedback;
}

type CheckInAction = UpdateLocationAction | UpdateFailureAction | UpdateCheckInOrOutFeedbackAction;

function reducer( state: CheckInPageState = {
	location: EmptyLocation,
	failure: undefined,
}, action : CheckInAction ) {
	switch ( action.type ) {
		case 'UPDATE_LOCATION':
			return sliceLocation( state, action );
		case 'UPDATE_FAILURE':
			return sliceFailure( state, action );
		case 'UPDATE_CHECK_IN_OR_OUT_FEEDBACK':
			return sliceCheckInOrOutFeedback( state, action );
		default:
			return state;
	}
}

export const store = createStore( reducer );
