import { Failure } from '@mrhenry/failure';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';
import { Dispatch } from 'redux';
import { FailureWithDetails, UnknownSetupError } from '../failure/data';
import { UpdateFailureAction } from '../failure/slice';
import { currentLanguage, Language } from '../language/data';
import { EmptyLocation, LocationData } from './data';
import { UpdateLocationAction } from './slice';

export async function hydrateLocation( dispatch: Dispatch<UpdateLocationAction|UpdateFailureAction> ): Promise<void> {
	const params = new URLSearchParams( location.search );
	const locationToken = params.get( 'location-token' );
	const language = currentLanguage();
	if ( !locationToken ) {
		dispatch( {
			type: 'UPDATE_LOCATION',
			location: EmptyLocation,
		} );

		return;
	}

	try {
		const location = await getLocationData( locationToken, language );
		if ( Failure.isFailure( location ) ) {
			dispatch( {
				type: 'UPDATE_FAILURE',
				failure: location,
			} );

			return;
		}

		dispatch( {
			type: 'UPDATE_LOCATION',
			location: location,
		} );

		return;
	} catch ( err ) {
		bugsnagClient.notify( err );

		dispatch( {
			type: 'UPDATE_FAILURE',
			failure: Failure.from( err.message, UnknownSetupError ),
		} );

		return;
	}
}

async function getLocationData( token: string, language: Language ): Promise<LocationData|FailureWithDetails> {
	bugsnagClient.addMetadata( 'location setup', {
		token: token,
		language: language,
	} );

	try {
		const response = await fetch( 'https://study360-api.mrhenry.eu/graphql', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( {
				query: `
					query q(
						$token: ID!,
						$language: Language!
					) {
						location: locationWithToken(token: $token) {
							additionalInformation(language: $language)
							id
							name
						}
					}
				`,
				variables: {
					token: token,
					language: language,
				},
			} ),
		} );

		if ( !response.ok ) {

			bugsnagClient.notify( new Error( `[study360 location setup] API Error: ${response.status}: ${response.statusText}` ) );

			return Failure.from( `API Error: ${response.status}: ${response.statusText}`, UnknownSetupError );
		}

		const respBody = await response.json();
		if ( responseIsLocationData( respBody.data.location ) ) {
			return {
				id: respBody.data.location.id,
				name: respBody.data.location.name,
				additionalInformation: {
					checkInBackgroundImage: respBody.data.location.additionalInformation.checkInBackgroundImage,
				},
				token: token,
			};
		}

		bugsnagClient.notify( new Error( '[study360 location setup] received unexpected data from API' ) );

		return Failure.from( 'received unexpected data from API', UnknownSetupError );
	} catch ( err ) {
		bugsnagClient.notify( err );

		return Failure.from( err.message, UnknownSetupError );
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function responseIsLocationData( location: any ): location is LocationData {
	return location && (
		( location.id && ( 'string' === typeof location.id ) ) &&
		( location.name && ( 'string' === typeof location.name ) ) &&
		( location.additionalInformation && 'object' === ( typeof location.additionalInformation ) ) &&
		( location.additionalInformation.checkInBackgroundImage && ( 'string' === typeof location.additionalInformation.checkInBackgroundImage ) )
	);
}
