import { FailureWithDetails } from '../failure/data';
import { EmptyLocation, LocationData } from './data';

export type UpdateLocationAction = {
	readonly type: 'UPDATE_LOCATION';
	readonly location: LocationData;
}

interface State {
	readonly location: LocationData;
	readonly failure?: FailureWithDetails;
}

export function sliceLocation<T extends State>( state: T, action: UpdateLocationAction ): T {
	if ( !action.location.id || !action.location.token ) {
		return {
			...state,
			location: EmptyLocation,
		};
	}

	return {
		...state,
		location: {
			additionalInformation: action.location.additionalInformation,
			id: action.location.id,
			name: action.location.name,
			token: action.location.token,
		},
		failure: undefined, /* clear the error when a location was set successfully */
	};
}
